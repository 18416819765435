import React from 'react';
import { LoadContainer, QuoteContainer } from 'components/cpaipw';
import { useHistory } from 'react-router-dom';
import { sortProducts } from '../../quote/policy/utils';
import { FormContext } from 'quote/FormContext';
import { PolicyActionContext, PolicyDataContext } from 'quote/policy/PolicyContext';
import { ApplicationPages, NavRoutes, preventDefault, ValidationMessages } from 'common/utils';
import { PolicyReviewItem } from 'quote/policy/PolicyReviewItem';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { ErrorMessage } from 'components/cpaipw/ErrorMessage';
import oliverAPI from 'api/OliverAPI';
import { SubmissionContext } from 'application/SubmissionContext';
import { ApplicationDataContext } from 'application/AboutYouQuestions/ApplicationContext';
import { Button } from 'components/buttons';

interface IProps {

}
interface SignatureFields {
	emailAddress: string;
	witnessName: string;
	witnessEmail: string;
}
const ApplicationReview: React.FC<IProps> = (props: IProps) => {
	const FormCtx = React.useContext(FormContext);
	const ApplicationCxt = React.useContext(ApplicationDataContext);
	const PolicyData = React.useContext(PolicyDataContext);
	const PolicyAction = React.useContext(PolicyActionContext);
	const SubmissionCtx = React.useContext(SubmissionContext);

	const [signatureDataLoading, setSignatureDataLoading] = React.useState<boolean>(false);

	const history = useHistory();

	const methods = useForm({
		defaultValues: {
			emailAddress: ApplicationCxt.applicationData.email,
			witnessName: '',
			witnessEmail: '',
		},
		mode: 'onSubmit',
		reValidateMode: 'onChange'
	});
	const { control, handleSubmit, errors } = methods;

	const quoteData = PolicyAction.createQuoteData(PolicyData.quoteData);
	const quoteTotal = PolicyData.quoteData.total;

	React.useEffect(() => {
		FormCtx.setCurrentPage(ApplicationPages.review);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onValidSubmit = async (vals: SignatureFields) => {
		setSignatureDataLoading(true);
		await oliverAPI.postSignerInformation({
			application_id: SubmissionCtx.applicationId,
			signers: [
				{
					role: 'applicant',
					name: ApplicationCxt.applicationData.firstName! + ' ' + ApplicationCxt.applicationData.lastName!,
					email: vals.emailAddress,
				},
			]
		});
		setSignatureDataLoading(false);
		history.push(NavRoutes.ApplicationNextSteps);
	};

	return (
		<FormProvider {...methods}>
			<QuoteContainer largeColumn={true}>
				<LoadContainer isLoading={signatureDataLoading}>
					<h3 className="line-dash-header">{ApplicationCxt.applicationData.firstName}, please review Your Policy</h3>
					<form onSubmit={preventDefault}>
						{quoteData.length > 0 && sortProducts(quoteData).map((item: any) => {
							return <PolicyReviewItem
								item={item.product}
								key={`item-${item.index}`}
							/>;
						})}
						<div className="d-flex flex-row justify-content-between px-3 py-3 mt-4 border-top border-bottom border-top-gray-400 border-bottom-gray-400">
							<h5 className="mb-0">Total</h5>
							<div className="d-flex align-items-end">
								<h5 className="mb-0 mr-1">${quoteTotal && quoteTotal.toFixed(2).toLocaleString()}</h5>
								<h6 className="mb-0">/mo</h6>
							</div>
						</div>
						<div className="row mt-4">
							<div className="col-lg-8">
								<p>Please confirm the following email as your policy will be emailed to that email address:</p>
								<div className="form-group d-block">
									<label htmlFor="email">Email Address</label>
									<Controller
										name="emailAddress"
										control={control}
										rules={{
											required: ValidationMessages.required,
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
												message: ValidationMessages.email
											}
										}}
										render={({ onChange, value }) => <input
											type="text"
											className="form-control"
											id="emailAddress"
											name="emailAddress"
											value={value}
											onChange={onChange}
										/>}
									/>
									<ErrorMessage>{errors?.emailAddress?.message}</ErrorMessage>
								</div>
								<div className="d-flex align-items-start justify-content-between">
									<div className="d-flex align-items-start">
										<Button className="btn-outline-primary mr-2" onClick={() => history.push(NavRoutes.ApplicationHistoryStep1)}>Back</Button>
										<div className="d-flex flex-column align-items-start align-self-start">
											<Button
												className="btn-primary"
												onClick={handleSubmit(onValidSubmit)}
											>
												Submit
											</Button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</LoadContainer>
			</QuoteContainer>
		</FormProvider>
	);
};

export default ApplicationReview;